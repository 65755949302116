import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/auth';

export default class Firebase {
  
  config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCK,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
  }

  /**
   * @param {String} resource
   * @param {Object} options
   * @param {Object} http
   */
  constructor(config = {}) {

    const c = {...config, ...this.config}
    if (app.apps.length === 0) {
      app.setLogLevel('error')
      app.initializeApp(c)
    }
  }

  /**
   * @return {this}
   */
  static build(config: any = null) {
    return new this(config)
  }

  get firebase() {
    return app
  }

  get db(): app.firestore.Firestore {
    return app.firestore()
  }
  
  get storageRef() {
    return app.storage().ref()
  }

  get dbTimestamp() {
    return this.firebase.firestore.FieldValue.serverTimestamp()
  }

  get deleteDbField() {
    return this.firebase.firestore.FieldValue.delete()
  }

  getCollectionRef(collectionPath: string) {
    return this.db.collection(collectionPath)
  }

}
