import FirestoreRepository from '../FirestoreRepository'
import User from '../models/User'
export default class UserRepository extends FirestoreRepository {

  public async store(user: User) {
    if (user.flags.has('firebase_stored')) return
    user.flags.reset()

    await super.store(user)
  }

  public async fetchManyUsers(ids: Array<string>) {
    return (await this.fetchMany(ids)).map(user => {
      const fetchedUser = User.fromData(user.data())
      fetchedUser.flags.add('from_firestore')
      return fetchedUser
    })
  }
    
}