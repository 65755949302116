import Flags from '../Flags'
import _pick from 'lodash/pick'
import FirestoreModel from '../FirestoreModel'

export default class User extends Object implements FirestoreModel {
  public _id: string
  public id: string
  public username: string
  public avatar: string
  public flags: Flags

  dataProps: Array<string> = ['username', 'avatar']
  
  constructor(id = '', username = '', avatar = '') {
    super()
    this.id = id.toString()
    this._id = this.id
    this.username = username
    this.avatar = avatar
    this.flags = new Flags()
  }

  public toData(): any {
    return {_id: this.id, ..._pick(this, this.dataProps)}
  }

  public static fromData(data): User {
    return new User(data._id, data.username, data.avatar)
  }

  public static fromDBUser(user): User {
    return new User(user.id, user.name, user.profile_picture)
  }
}