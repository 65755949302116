import { v4 as uuidv4 } from 'uuid'
import FirestoreModel from '../FirestoreModel'
import User from './User'

export interface RoomType {
    name: string,
    value: any
}

export default class ChatRoom extends Object implements FirestoreModel {
  id: string
  roomName: string
  description: string
  users: Array<User>
  lastUpdated: Date
  dataProps: Array<string> = ['roomName', 'description', 'users', 'lastUpdated']
  type: RoomType

  constructor (id = '', name = '', description = '', users: Array<User> = []) {
    super()
    this.id = id ? id : uuidv4()
    this.type = {name: '', value: ''}
    this.roomName = name
    this.description = description
    this.users = users
    this.lastUpdated = new Date()
  }

  get usersId() {
    return this.users.map((user) => {
      return user.id
    })
  }

  public setType(name, value) {
    this.type.name = name
    this.type.value = value
  }

  public toData() {
    return {
      roomName: this.roomName,
      description: this.description,
      lastUpdated: this.lastUpdated,
      users: this.usersId,
      typingUsers: [],
      type: this.type
    }
  }

  public toFormData() {
    return {...this.toData(), users: this.users}
  }

  public static fromData(data, id = '') {
    const room = new this(id, data.roomName, data.description, data.users)
    if ('type' in data) {
      room.setType(data.type.name, data.type.value)
    }
    return room
  }
}