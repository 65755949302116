export default class Flags {
  public flags: Array<string>

  constructor (flags: Array<string> = []) {
    this.flags = flags
  }

  public has(flag: string): Number | Boolean {
    const found = this.flags.findIndex((_flag) => _flag === flag)
    return found !== -1
  }

  public hasAny(flags: Array<string>): Boolean {
    let found = false
    flags.forEach(flag => {
      if (this.has(flag)) {
        found = true
      }
    })
    return found
  }

  public add(flag: string): void {
    if (this.has(flag)) return
    this.flags.push(flag)
  }

  public remove(flag: string): Boolean {
    const hasFlag: number = this.flags.indexOf(flag)
    if (hasFlag === -1) return false

    return !!this.flags.splice(hasFlag, 1)
  }

  public reset(): void {
    this.flags = []
  }
}
