import FirestoreModel from './FirestoreModel'
import Firebase from 'firebase'
export default class FirestoreRepository {
  private source: Firebase.firestore.CollectionReference

  constructor (reference: Firebase.firestore.CollectionReference<Firebase.firestore.DocumentData>) {
    this.source = reference
  }

  private getReference(id: string): Firebase.firestore.DocumentReference {
    return this.source.doc(id)
  }

  public async fetch(id: string): Promise<Firebase.firestore.DocumentSnapshot> {
    const reference = this.getReference(id)
    return await reference.get()
  }

  public async fetchMany(ids: Array<string>) {
    const fetched: Array<Promise<Firebase.firestore.DocumentSnapshot>> = []
    ids.forEach((id) => {
      fetched.push(this.fetch(id))
    })
    return await Promise.all(fetched)
  }

  public async store(model: FirestoreModel) {
    const firebaseUser = await this.fetch(model.id)
    await firebaseUser.ref.set(model.toData(), {merge: firebaseUser.exists})
  }

  public async storeMany(models: Array<FirestoreModel>) {
    const stored: Array<Promise<void>> = []
    models.forEach((model) => {
      stored.push(this.store(model))
    })
    await Promise.all(stored)
  }

}