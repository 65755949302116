import FirestoreRepository from '@/chat/firestore/FirestoreRepository'
import ChatRoom from '@/chat/firestore/models/ChatRoom'
import User from '@/chat/firestore/models/User'
import UserRepository from '@/chat/firestore/repositories/UserRepository'
import Firebase from './Firebase'

export default class FirebaseChatService extends Firebase {

  private static instance: FirebaseChatService | null = null

  _vm: any
  chatroomRepository: FirestoreRepository | null = null
  userRepository: UserRepository | null = null

  constructor(vuevm = null, config = {}) {
    super(config)
    this._vm = vuevm
  }

  public static getInstance(vm, config: Object = {}): FirebaseChatService {
    if (this.instance === null) {
      this.instance = new this(vm, config)
    }
    return this.instance
  }

  async initialize(token: string) {
    this.chatroomRepository = new FirestoreRepository(this.roomsRef)
    this.userRepository = new UserRepository(this.usersRef)
  }

  async signInWithCustomToken(token: string) {
    await this.firebase.auth().signInWithCustomToken(token)
  }

  get usersRef() {
    return this.getCollectionRef('users')
  }

  get roomsRef() {
    return this.getCollectionRef('chatRooms')
  }

  getMessagesRef(roomId: string) {
    return this.roomsRef.doc(roomId).collection('messages')
  }

  get filesRef() {
    return this.storageRef.child('files')
  }

  async roomRefByClassId(classId: string) {
    return this.roomsRef
    .where('type.name', '==', 'class')
    .where('type.value', '==', parseInt(classId))
    .get()
  }

  async fetchRoom(id: string): Promise<ChatRoom|undefined> {
    const data = this.chatroomRepository ? (await this.chatroomRepository.fetch(id)).data() : null
    if (data) {
      const users = this.userRepository ? await this.userRepository.fetchManyUsers(data.users) : []
      const room = new ChatRoom(id, data.roomName, data.description, users)
      if ('type' in data) room.setType(data.type.name, data.type.value)
      return room
    }
  }

  async storeRoom(data: any, id: string = '') {
    data.users = data.users.filter((user) => !user.flags.has('to_remove'))

    // Returning existing room when class type is defined.
    if ('type' in data && data.type.name === 'class' && data.type.value) {
      const classId = data.type.value.toString()
      const foundRooms = await this.roomRefByClassId(classId)
      const foundRoom = foundRooms.docs[0]
      id = foundRoom && foundRoom.exists ? foundRoom.id || '' : ''
    }

    const room = ChatRoom.fromData(data, id)
    const newUsers = room.users.filter(user => !user.flags.has('from_firestore'))
    if (this.userRepository && newUsers.length > 0) { 
      await this.userRepository.storeMany(newUsers)
    }

    if (this.chatroomRepository) {
      await this.chatroomRepository.store(room)
    }
  }

  async assignUserToRoom(id: string, user: User) {
    const room = await this.fetchRoom(id)
    if (room) {
      room.users.push(user)
      await this.storeRoom(room, id)
    }
  }

  async assignUserToClassChat(classId: number, user: User) {
    const foundRoom = (await this.roomRefByClassId(classId.toString())).docs[0]
    if (!foundRoom.exists) return
    await this.assignUserToRoom(foundRoom.id, user)
  }

}